const namespaced = true

import api from '../../api/user.js';
import _ from 'lodash';

const state = {
    user: [],
    role: -1,
    users: [],
    users_sso: [],
}
const mutations = {
    MUTATION_SET_USER(state, value) {
        state.user = value;
    },
    MUTATION_SET_USERS(state, value) {
        state.users = value;
    },
    MUTATION_SET_USERS_SSO(state, value) {
        state.users_sso = value;
    },
    MUTATION_ADD_USER(state, value) {
        state.users.push(value);
    },
    MUTATION_UPDATE_USER(state, value) {
        let users = state.users;
        state.users = [];
        _.forEach(users, user => {
            if (user.id != value.id) {
                state.users.push(user);
            } else {
                state.users.push(value);
            }
        });
    },
    MUTATION_DELETE_USER(state, value) {
        let users = state.users;
        state.users = [];
        _.forEach(users, user => {
            if (user.id != value) {
                state.users.push(user);
            }
        });
    },
    SET_ROLE_USER(state, input_data) {
        state.role = input_data;

    },

    MUTATION_SET_USERS_WEIGHING_MACHINE(state, value) {
        const user = _.find(state.users, { id: value.user_id });
        if (user) {
            user.weighingMachine.push(value.weighing_machine);
        }
    },
    MUTATION_DELETE_USERS_WEIGHING_MACHINE(state, value) {
        const user = _.find(state.users, { id: value.user_id });
        if (user && user.weighingMachine) {
            _.remove(user.weighingMachine, (machine) => machine.id === value.weighing_machine_id);
        }
    }


}
const actions = {
    getInformation(context) {
        return new Promise((resolve, reject) => {
            api.getInformation().then(function (res) {
                context.commit('MUTATION_SET_USER', res.data.data);
                resolve(res.data.data);
            }).catch(function (error) {
                reject(error);
            })
        })
    },
    getUsers(context) {
        return new Promise((resolve, reject) => {
            api.getUsers().then(resp => {
                context.commit('MUTATION_SET_USERS', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getUsersSSO(context) {
        return new Promise((resolve, reject) => {
            api.getUsersSSO().then(resp => {
                context.commit('MUTATION_SET_USERS_SSO', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    store(context, data) {
        return new Promise((resolve, reject) => {
            api.store(data).then(resp => {
                context.commit('MUTATION_ADD_USER', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    update(context, { id, data }) {
        return new Promise((resolve, reject) => {
            api.update(id, data).then(resp => {
                context.commit('MUTATION_UPDATE_USER', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    delete(context, id) {
        return new Promise((resolve, reject) => {
            api.deleted(id).then(resp => {
                context.commit('MUTATION_DELETE_USER', id);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    //Asisgnar/Quitar Rol a Usuario
    assignRolToUser(context, { user, role }) {
        return new Promise((resolve, reject) => {
            api.assignRolToUser(user, role).then(resp => {
                context.commit('MUTATION_SET_USERS', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    retractRolFromUser(context, { user, role }) {
        return new Promise((resolve, reject) => {
            api.retractRolFromUser(user, role).then(resp => {
                context.commit('MUTATION_SET_USERS', resp.data);
                resolve(resp.data);
            }).catch(error => reject(error));
        });
    },
    allowAbilityToUser(context, { user, ability }) {
        return new Promise((resolve, reject) => {
            api.allowAbilityToUser(user, ability).then(resp => {
                context.commit('MUTATION_SET_USERS', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    disallowAbilityToUser(context, { user, ability }) {
        return new Promise((resolve, reject) => {
            api.disallowAbilityToUser(user, ability).then(resp => {
                context.commit('MUTATION_SET_USERS', resp.data);
                resolve(resp.data);
            }).catch(error => reject(error));
        });
    },

    assignWeighingMachinesToUser(context, data) {
        return new Promise((resolve, reject) => {
            api.assignWeighingMachinesToUser(data).then(resp => {
                context.commit('MUTATION_SET_USERS_WEIGHING_MACHINE', resp.data.data);
                resolve(resp.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
            }).catch(error => {
                reject(error)
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },
    deleteWeighingMachinesToUser(context, data) {
        return new Promise((resolve, reject) => {
            api.deleteWeighingMachinesToUser(data).then(resp => {
                context.commit('MUTATION_DELETE_USERS_WEIGHING_MACHINE', data);
                resolve(resp.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
            }).catch(error => {
                reject(error)
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    }
}

const getters = {
    GET_STATUS_LOGGED: state => state.configuration.isLoggedIn ? state.configuration.isLoggedIn : false,
    filteredWeighingMachines(state, getters, rootState) {
        const userMachines = rootState.configuration.userAuth.weighingMachine.map(
            (o) => o.id
        );
        return rootState.weighing_machines.weighing_machines.filter((weighingMachine) =>
            userMachines.includes(weighingMachine.id)
        );

    },

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}