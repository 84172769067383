const namespaced = true

import api from '../../../api/Sap/partnerbusiness.js';

const state = {
     partnersbusiness: [],
     partnersbusinessName: ''
}
const mutations = {
     MUTATION_SET_PARTNERSBUSINESS(state, data) {
          state.partnersbusiness = data;
     },
     MUTATION_SET_ONE_PARTNERSBUSINESS(state, data) {
          (data.cardName) ? state.partnersbusinessName = data.cardName : state.partnersbusinessName = 'No disponible';
     },
}
const actions = {
     // Name Property
     getPartnersbusiness({ commit, rootState, dispatch }, { dbname, type }) {
          commit('tickets/MUTATION_SET_OVERLAY', true, { root: true });
          return new Promise((resolve) => {
               api.getPartnersbusiness(dbname, type).then(function (res) {
                    commit('MUTATION_SET_PARTNERSBUSINESS', res.data);
                    resolve(res.data);
               }).catch(function (error) {

                    commit('tickets/MUTATION_SET_OVERLAY', false, { root: true });
                    commit('tickets/MUTATION_SET_BANNER', false, { root: true });

                    let movefromtickets = rootState.configuration.move;

                    // limpieza de states
                    if (movefromtickets != "") {
                         dispatch('cleanStatestooffline', { root: true });
                    } else {
                         dispatch('cleanStatestooffline', { root: true });
                         dispatch('tickets/cleanmove', { root: true });
                    }

                    // limpieza de vista
                    commit('configuration/SET_WEIGHING_MACHINE', {}, { root: true });

                    console.log(error)
               })
          })
     },
     cleanPartnersbusiness(context) {
          context.commit('MUTATION_SET_PARTNERSBUSINESS', []);
     },
     cleanStatestooffline(context) {
          context.dispatch('tickets/cleanArticlesfromtickets', [], { root: true });
          context.dispatch('tickets/cleanTicketsTemp', [], { root: true });
          context.dispatch('tickets/cleanTickets', [], { root: true });
          context.dispatch('items/cleanItems', [], { root: true });
          context.dispatch('partnerbusiness/cleanPartnersbusiness', [], { root: true });
          context.dispatch('tickets/cleanBusinesspartnersfromtickets', [], { root: true });
          context.dispatch('tickets/cleanTicketsbymove', [], { root: true })
     },
     getOnePartnersbusiness(context, { dbname, code }) {
          return new Promise((resolve) => {
               api.getOneBusinesspartners(dbname, code).then(function (res) {
                    context.commit('MUTATION_SET_ONE_PARTNERSBUSINESS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     }
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
