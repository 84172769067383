const namespaced = true

import api from '../../api/tickets_change_request.js';
import _ from 'lodash';

const state = {
    changeRequest: [],
    ticketsSap: [],
    ticketsDecline: [],
    ticketsProcess: [],
    countchangeRequest: {
        inProcess: 0,
        inSap: 0,
        inDecline: 0,
    },
    myChangeRequest: [],
    history: [],
    filterHystory: {
        start_date: new Date(Date.now()).toISOString().substr(0, 10),
        end_date: new Date(Date.now()).toISOString().substr(0, 10),
    },
    filterHystorySupports: {
        start_date: new Date(Date.now()).toISOString().substr(0, 10),
        end_date: new Date(Date.now()).toISOString().substr(0, 10),
    },
    selectedOption: [],
    items: [
        { id: 1, name: 'El dia de Hoy' },
        { id: 2, name: 'De 3 dias anteriores' },
        { id: 3, name: 'De 1 semana anterior' },
        { id: 4, name: 'De 1 mes anterior' },
        { id: 5, name: 'Seleccionar Fechas Manualmente' }
    ],
    changeModeSearch: 'El dia de Hoy',
    changeModeSearchSupport: 'El dia de Hoy'

}
const mutations = {
    MUTATION_SET_CHANGE_REQUEST(state, value) {
        state.changeRequest = value;
        let changeRequest = state.changeRequest;
        //Penidente cambiar a filter
        _.forEach(changeRequest, changeRequest => {
            switch (changeRequest.cat_estatusProd) {
                case 3:
                    state.ticketsSap.push(changeRequest);
                    state.countchangeRequest.inSap = state.countchangeRequest.inSap + 1;
                    break;
                case 4:
                    state.ticketsDecline.push(changeRequest);
                    state.countchangeRequest.inDecline = state.countchangeRequest.inDecline + 1;
                    break;
                default:
                    state.ticketsProcess.push(changeRequest);
                    state.countchangeRequest.inProcess = state.countchangeRequest.inProcess + 1;
                    break;
            }
        });

    },
    MUTATION_RESET_CHANGE_REQUEST(state) {
        state.changeRequest = [];
        state.ticketsSap = [];
        state.ticketsDecline = [];
        state.ticketsProcess = [];
        state.history = [];
        state.countchangeRequest.inProcess = 0;
        state.countchangeRequest.inSap = 0;
        state.countchangeRequest.inDecline = 0;
    },
    MUTATION_SET_MY_CHANGE_REQUEST(state, value) {
        state.myChangeRequest = value;
    },
    MUTATION_RESET_MY_CHANGE_REQUEST(state) {
        state.myChangeRequest = [];
    },
    MUTATION_ACCEPT_DECLINE(state, { id, catEstatus }) {
        let ticketsSaps = [];
        let ticketsDeclines = [];
        let ticketsProcesss = [];
        switch (catEstatus) {
            case 3:
                ticketsSaps = state.ticketsSap;
                state.ticketsSap = [];
                _.forEach(ticketsSaps, (change) => {
                    if (change.id != id) {
                        state.ticketsSap.push(change);
                    }
                });
                state.countchangeRequest.inSap = state.countchangeRequest.inSap - 1;
                break;
            case 4:
                ticketsDeclines = state.ticketsDecline;
                state.ticketsDecline = [];
                _.forEach(ticketsDeclines, (change) => {
                    if (change.id != id) {
                        state.ticketsDecline.push(change);
                    }
                });
                state.countchangeRequest.inDecline = state.countchangeRequest.inDecline - 1;
                break;
            default:
                ticketsProcesss = state.ticketsProcess;
                state.ticketsProcess = [];
                _.forEach(ticketsProcesss, (change) => {
                    if (change.id != id) {
                        state.ticketsProcess.push(change);
                    }
                });
                state.countchangeRequest.inProcess = state.countchangeRequest.inProcess - 1;
                break;
        }

    },
    MUTATION_HISTORY(state, value) {
        state.history = value;
    },
    MUTATION_DATE(state, data) {
        state.filterHystory.end_date = new Date(Date.now()).toISOString().substr(0, 10);
        switch (data) {
            case 2:
                state.filterHystory.start_date = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearch = 'De 3 dias anteriores';
                break;
            case 3:
                state.filterHystory.start_date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearch = 'De 1 semana anterior';

                break;
            case 4:
                state.filterHystory.start_date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearch = 'De 1 mes';
                break;
            default:
                state.filterHystory.start_date = new Date(Date.now()).toISOString().substr(0, 10);
                state.changeModeSearch = 'El dia de Hoy';
                break;
        }
    },
    MUTATION_DATE_ESPECIFIC(state, data) {
        state.filterHystory.start_date = data.start_date;
        state.filterHystory.end_date = data.end_date;
    },
    MUTATION_DATE_ESPECIFIC_SEARCH(state){
        state.changeModeSearch = 'Seleccionar Fechas Manualmente';
    },
    MUTATION_DATE_SUPPORT(state, data) {
        state.filterHystorySupports.end_date = new Date(Date.now()).toISOString().substr(0, 10);
        switch (data) {
            case 2:
                state.filterHystorySupports.start_date = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearchSupport = 'De 3 dias anteriores';
                break;
            case 3:
                state.filterHystorySupports.start_date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearchSupport = 'De 1 semana anterior';

                break;
            case 4:
                state.filterHystorySupports.start_date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
                state.changeModeSearchSupport = 'De 1 mes';
                break;
            default:
                state.filterHystorySupports.start_date = new Date(Date.now()).toISOString().substr(0, 10);
                state.changeModeSearchSupport = 'El dia de Hoy';
                break;
        }
    },
    MUTATION_DATE_ESPECIFIC_SUPPORT(state, data) {
        state.filterHystorySupports.start_date = data.start_date;
        state.filterHystorySupports.end_date = data.end_date;
    },
    MUTATION_DATE_ESPECIFIC_SEARCH_SUPPORT(state){
        state.changeModeSearchSupport = 'Seleccionar Fechas Manualmente';
    }
}
const actions = {
    getChangeRequest(context) {
        return new Promise((resolve, reject) => {
            api.get().then(res => {
                context.commit('MUTATION_RESET_CHANGE_REQUEST');
                context.commit('MUTATION_SET_CHANGE_REQUEST', res.data.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                resolve(res.data);
            }).catch(function (error) {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                reject(error.data);

            })
        })
    },
    resetChangeRequest(context) {
        context.commit('MUTATION_RESET_CHANGE_REQUEST');
    },
    myChangeRequest(context) {
        return new Promise((resolve, reject) => {
            api.getMyChangeRequest(context.state.filterHystory).then(res => {
                context.commit('MUTATION_RESET_MY_CHANGE_REQUEST');
                context.commit('MUTATION_SET_MY_CHANGE_REQUEST', res.data.data);
                context.dispatch('notifications/add', { 'color': 'accent', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                resolve(res.data);
            }).catch(function (error) {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                reject(error.data);

            })
        })
    },
    resetMyChangeRequest(context) {
        context.commit('MUTATION_RESET_MY_CHANGE_REQUEST');
    },
    acceptChangeRequest(context, { id, catEstatus }) {
        return new Promise((resolve, reject) => {
            api.accpetChangeRequest(id).then(res => {
                context.commit('MUTATION_ACCEPT_DECLINE', { id, catEstatus });
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            }).catch(error => {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },
    declineChangeRequest(context, { id, data, catEstatus }) {
        return new Promise((resolve, reject) => {
            api.declineChangeRequest(id, data).then(res => {
                context.commit('MUTATION_ACCEPT_DECLINE', { id, catEstatus });
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                resolve(res.data);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                reject(error);
            });
        });
    },
    getHistory(context) {
        return new Promise((resolve, reject) => {
            api.gethistory(context.state.filterHystorySupports).then(res => {
                context.commit('MUTATION_HISTORY', res.data.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                resolve(res.data);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                reject(error);
            });
        });
    },
    changeDate(context, data) {
        context.commit('MUTATION_DATE', data);
        context.dispatch('myChangeRequest');
    },
    changeDateEspecific(context, data) {
        context.commit('MUTATION_DATE_ESPECIFIC', data);
        context.dispatch('myChangeRequest');
    },
    changeDateEspecificSearch(context) {
        context.commit('MUTATION_DATE_ESPECIFIC_SEARCH');
    },
    changeDateSupport(context, data) {
        context.commit('MUTATION_DATE_SUPPORT', data);
        context.dispatch('getHistory');
    },
    changeDateSupportEspecific(context, data) {
        context.commit('MUTATION_DATE_ESPECIFIC_SUPPORT', data);
        context.dispatch('getHistory');
    },
    changeDateSupportEspecificSearch(context) {
        context.commit('MUTATION_DATE_ESPECIFIC_SEARCH_SUPPORT');
    },
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
