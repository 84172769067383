let prefix = '/api/connection/';

function getTickets(date1,date2,company,plant,weighing_machine_id) {
    return window.axios.get(prefix + weighing_machine_id +'/tickets/pending-upload-sap', {params:{date1: date1,date2: date2,company:company, plant:plant}});
}

function exportExcel(data){
    return window.axios.post(prefix+'tickets/reportExcel',data,{ responseType: 'blob' });
}

export default {
    getTickets,
    exportExcel
}