// import * as firebase from 'firebase';
// import "firebase/auth";

import { isNull } from 'lodash';
import api from '../../api/authentication.js';
import router from '../../routes/index.js';

const namespaced = true

const state = {
    iaccept: false,
    isLoggedIn: false,
    showLogoutDialog : false,
    providerParameters : {
        prompt : 'select_account'
    }
}

const mutations = {
    MUTATION_SET_LOGOUT(state, value) {
        state.showLogoutDialog = value;
    },
    SET_LOGINSTATUS(state, input_data){
        state.isLoggedIn = input_data;
    },
}

const actions = {
    login(context, data) {
        return new Promise((resolve) => { 
            api.login(data).then(function (res) {
                context.dispatch('notifications/add',{'color': isNull(res.data.token) ? 'error' : 'success', 'visibility' : true, 'timeout': 5000, 'text': res.data.message}, {root: true });
                context.dispatch('redirect_after_login', res.data)
                context.dispatch('configuration/setUserAuth',res.data.user,{root: true })
                context.dispatch('ticketState/getTicketState',{root: true })
                context.dispatch('typeDocument/getTypeDocument', {root: true })
                resolve(res);
            }).catch(function (error) {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error.response.data.data.message}, {root: true });
            });
        });
   },
    redirect_after_login(context, data) {
        if(!isNull(data.token)){
            router.push('/home');
        }
    },
    redirect_after_logout(context, data) {
        context.dispatch('configuration/setUserAuth',{ id: 0},{root: true });
        context.commit('tickets/MUTATION_SET_TICKETS',[],{root: true });
        context.commit('tickets/MUTATION_SET_TICKETSTEMP',[],{root: true });
        context.commit('configuration/SET_WEIGHING_MACHINE',[],{root: true });
        context.dispatch('plants/cleanPlants',[],{root: true });
        context.dispatch('companies/cleanCompanies',[],{root: true });
        
        if(!isNull(data.code) || !data.code){
            router.push('/login');
        }
    },
    check_token(context){
        api.check_token().then(function (res) {
            if(res.data.code){
                context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': res.data.message}, {root: true });
                if(router.currentRoute.name == 'login'){
                    router.push('/home');
                }
            }else {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': res.data.message}, {root: true });
                if(router.currentRoute.name != 'login'){
                    router.push('/login');
                }
            }
        }).catch(function (error) {
            console.log(error);
            if(router.currentRoute.name != 'login'){
                router.push('/login');
            }
        })
   },
   logout(context){
        api.logout().then(function (res) {
            context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': res.data.message}, {root: true });
            context.dispatch('redirect_after_logout',res.data);
        }).catch(function() {
            context.dispatch('redirect_after_logout',{'code' : false});
        })
   }
}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}