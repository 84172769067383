<template>
    <div>
        <loader-component v-if="loading"></loader-component>
        <v-app-bar app clipped-left fixed>
            <v-row align="center">
                <v-col cols="2" sm="1" md="1" lg="1" xl="1">
                    <v-btn block outlined @click.stop="drawerConf = !drawerConf">
                        <span class="mdi mdi-menu"></span>
                    </v-btn>
                </v-col>
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <span class="subtitle-2 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }} </span>
                </v-col>
            </v-row>
            <v-badge :color='(weighing_machine.status_color != undefined) ? weighing_machine.status_color : "#f0ad4e"'
                dot>
                {{ (Object.keys(weighing_machine).length === 0) ? "Sin conexión" : "Conexión: " + weighing_machine.name
                }}
                &nbsp;
            </v-badge>

            &nbsp;
        </v-app-bar>

        <v-navigation-drawer v-model="drawerConf" temporary fixed width="25%">
            <template v-slot:prepend>
                <v-list-item class="px-4 pt-1">
                    <v-list-item-title>Menú</v-list-item-title>
                    <v-btn icon @click.stop="drawerConf = !drawerConf">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </template>
            <v-divider></v-divider>

            <v-list dense nav rounded>
                <v-list-item-group no-action color="accent" v-if="false">
                    <v-list-item @click="goTo('dashboard')">
                        <v-list-item-icon>
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Dashboard</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <v-list-group v-for="module in modulesOrder" :key="module.title" v-show="can(module.permissions)"
                    v-model="module.active" :prepend-icon="module.action" no-action color="accent">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ module.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-for="section in module.sections" :key="section.title" link
                        @click="goTo(section.redirect)">
                        <v-list-item-content>
                            <v-list-item-title v-show="can(section.permissions)">{{ section.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <v-divider></v-divider>
                <v-subheader class="mt-2">Configuración</v-subheader>
                <v-list-item link>
                    <v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-brightness-6</v-icon>
                        </v-list-item-icon>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch color="accent" v-model="get_is_dark"
                            v-on:change="setDarkMode(get_is_dark)"></v-switch>
                    </v-list-item-action>
                </v-list-item>
                <v-select label="Conexiones" v-model="selected_weighing_machines" item-color="accent"
                    :items="filteredWeighingMachines" item-text="name"
                    :color='(weighing_machine.status_color != undefined) ? weighing_machine.status_color : "#f0ad4e"'
                    dense prepend-inner-icon="mdi-connection" outlined return-object>
                    <template v-slot:[`item`]="{ item }">{{ item.name + ' - ' + item.db_database }}</template>
                </v-select>
            </v-list>
            <template v-slot:append>
                <v-list-item link @click="goTo('home')">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ getConfigurationName }} (Inicio)
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon color="error">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :style="{ color: styleErrorColor }">
                            Cerrar Sesión
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import _ from 'lodash';
import default_profile_picture from '../users/images/default_profile_picture.png';
import { can } from '../../src/helpers/permissions';
import { mapState, mapActions, mapGetters } from 'vuex';
import LoaderComponent from './LoaderComponent.vue';


export default {
    props: ['config'],
    data: () => ({
        loading: false,
        selected_weighing_machines: {},
        drawer: true,
        group: null,
        drawerConf: false,
        modules: [
            {
                title: 'Administración',
                action: 'mdi-account-group',
                active: false,
                permissions: [
                    'get-users', 'store-users', 'update-users', 'delete-users',
                    'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
                    'get-roles', 'store-roles', 'update-abilities', 'delete-abilities', 'warehouse-to-user',
                ],
                sections: [
                    {
                        title: 'Usuarios, Permisos y Roles',
                        redirect: 'users',
                        permissions: [
                            'get-users', 'store-users', 'update-users', 'delete-users',
                            'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
                            'get-roles', 'store-roles', 'update-abilities', 'delete-abilities', 'warehouse-to-user'
                        ],
                    },
                ],
                banner: false,
            },
            {
                title: 'Configuraciones',
                action: 'mdi-cog',
                active: false,
                permissions: [
                    'get-weighing-machines', 'store-weighing-machines', 'update-weighing-machines', 'delete-weighing-machines'
                ],
                sections: [
                    {
                        title: 'Báscula',
                        redirect: 'settings/weighing-machines',
                        permissions: [
                            'get-weighing-machines', 'store-weighing-machines', 'update-weighing-machines', 'delete-weighing-machines'
                        ],
                    },
                ],
                banner: false,
            },
            {
                title: 'Basculas',
                action: 'mdi-scale',
                active: true,
                permissions: [
                    'nav-weighing-machine'
                ],
                sections: [
                    {
                        title: 'Mis Soportes',
                        redirect: 'tickets/mis-soportes',
                        permissions: [
                            'my-change-request'
                        ]
                    },
                    
                    {
                        title: 'Solicitudes de cambio',
                        redirect: 'tickets/solicitudes-cambio',
                        permissions: [
                            'nav-change-request'
                        ]
                    },
                    {
                        title: 'Tickets',
                        redirect: 'tickets',
                        permissions: [
                            'get-tickets', 'store-tickets', 'create-request-ticket'
                        ]
                    },
                    {
                        title: 'Transferencias de stock',
                        redirect: 'transferencias',
                        permissions: [
                            'get-tickets', 'store-tickets', 'create-request-ticket'
                        ]
                    }
                ],
                banner: false,

            },
            {
                title: 'Catálogos',
                action: 'mdi-cloud',
                active: true,
                permissions: ['nav-catalogue'],
                sections: [
                    {
                        title: 'Departamentos',
                        redirect: 'catalogue/departaments',
                        permissions: ['nav-departament'],
                    },
                    {
                        title: 'Campos del Ticket',
                        redirect: 'catalogue/tickets-fields',
                        permissions: ['nav-tickets-field'],
                    },
                    {
                        title: 'Sociedades',
                        redirect: 'catalogue/societies',
                        permissions: ['nav-societies'],
                    },
                ],
                banner: false,
            },
        ],
    }),
    mounted() {

        this.sleep(3000).then(() => {
            if (this.isLoggedIn === true) { this.index() }
            this.selected_weighing_machines = (this.weighing_machine === undefined) ? {} : this.weighing_machine;
        });

    },
    components: {
        LoaderComponent
    },
    computed: {
        ...mapGetters('configuration', ['getAbilitiesUser', 'getConfigurationName']),
        ...mapGetters('user', ['filteredWeighingMachines']),
        ...mapState('authentication', ['userAuth']),
        ...mapState('configuration', ['themeDark', 'weighing_machine', 'isLoggedIn']),
        ...mapState('weighing_machines', ['weighing_machines']),
        ...mapState('tickets', ['overlay']),
        logged() {
            return (this.$route.name !== "home")
        },
        sideMenu() {
            return this.$vuetify.breakpoint.width <= 1264 && this.logged
        },
        get_is_dark: {
            get: function () {
                return this.themeDark;
            },
            set: function (newValue) {
                return newValue;
            }
        },
        modulesOrder() {
            return _.orderBy(this.modules, 'title', 'asc');
        },
        styleErrorColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
        }
    },
    methods: {
        ...mapActions('notifications', ['add']),
        ...mapActions('plants', ['getPlants', 'cleanPlants']),
        ...mapActions('companies', ['getCompanies', 'cleanCompanies']),
        ...mapActions('weighing_machines', ['index']),
        ...mapActions('configuration', ['setDarkMode', 'setWeighingMachine']),///////
        ...mapActions('tickets', ['cleanBusinesspartnersfromtickets', 'cleanTickets', 'cleanTicketsTemp', 'cleanmove', 'cleanArticlesfromtickets', 'cleanTicketsbymove']),
        ...mapActions('items', ['cleanItems']),
        ...mapActions('partnerbusiness', ['cleanPartnersbusiness']),
        ...mapActions('warehouses', ['getWarehouses', 'cleanWarehouses']),
        can(permissions) {
            return can(this.getAbilitiesUser, permissions);
        },
        logout() {
            this.$router.push({ name: "logout" });
        },
        goTo(name) {
            this.$router.push({ name: name }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },
        showhere(route) {
            return (this.$route.fullPath != route) ? true : false;
        },
        checkImage(image) {
            if (image == 'default_user_image.jpg' || image == 'default_profile_picture.png' || image == null) {
                return default_profile_picture;
            } else {
                return image;
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
    watch: {
        get_is_dark: function (val) {
            this.$vuetify.theme.dark = val;
        },
        selected_weighing_machines: function () {

            this.cleanPartnersbusiness();
            this.cleanBusinesspartnersfromtickets()
            this.cleanItems();
            this.cleanTickets();
            this.cleanTicketsTemp();
            this.cleanTicketsbymove();
            this.cleanArticlesfromtickets();

            this.setWeighingMachine(this.selected_weighing_machines)


            if (this.weighing_machine.id != undefined && this.isLoggedIn === true) {
                this.loading = true;
                this.add({ 'color': 'success', 'visibility': true, 'timeout': 4000, 'text': "Conexion: " + this.weighing_machine.name + ' - ' + this.weighing_machine.db_database + " seleccionada" });
                this.getWarehouses(this.weighing_machine.id).then(() => {

                    this.getPlants(this.weighing_machine.id).then(() => {

                        this.getCompanies(this.weighing_machine.id).then(() => {
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                            console.log(error);
                        })

                    }).catch((error) => {
                        this.loading = false;
                        console.log(error);
                    })

                }).catch((error) => {
                    this.loading = false;
                    console.log(error);
                })


            }

        }
    },
    beforeMount() {
        this.$vuetify.theme.dark = this.get_is_dark;
    }
}
</script>

<style>
.router-link-exact-active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 5px;
    color: #1867c0 !important;
    caret-color: #1867c0 !important;
    font-style: italic;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
}
</style>