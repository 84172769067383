let prefix = 'api/sap/';

// trae todos los almacenes
function getPartnersbusiness(dbname, type) {
    return window.axios.get(prefix+'businesspartners', {params:{dbname: dbname,type: type}});
}

function getOneBusinesspartners(dbname, code) {
    return window.axios.get(prefix+'businesspartner/', {params:{dbname: dbname,code:code}});
}

export default {
    getPartnersbusiness,
    getOneBusinesspartners
}