const namespaced = true

import _ from 'lodash';

const state = {
    themeDark: false,
    userAuth: {
        id: 0,
        name: '',
        email: null,
        abilities: [],
        roles: [],
        slp_code: '',
        whs:[],
        weighingMachine:[],
    },
    weighing_machine:{},
    isLoggedIn: false,
}

const mutations = {
    SET_WEIGHING_MACHINE(state,value){
        state.weighing_machine = value;
    },
    SET_CONFIGURATION_USER_AUTH(state, value) {
        state.userAuth = value;
        state.isLoggedIn = (value.id) ? true : false;
    },
    SET_CONFIGURATION(state, input_data) {
        state = input_data;
    },
    SET_THEME_MODE(state, input_data) {
        state.themeDark = input_data;
    },
}

const actions = {
    setWeighingMachine(context,value){
        context.commit('SET_WEIGHING_MACHINE', value);
    },
    resetConfiguration(context) {
        context.commit('SET_CONFIGURATION', { themeDark: false });
    },
    setDarkMode(context, value) {
        if (value) {
            context.commit('SET_THEME_MODE', false);
        } else {
            context.commit('SET_THEME_MODE', true);
        }
    },
    setUserAuth(context, value) {
        context.commit('SET_CONFIGURATION_USER_AUTH', value);
    },
    getWeighingMachine(state){
        return state.weighing_machine.id;
    }

}

const getters = {
    getConfigurationID(state){
        return state.userAuth.id
    },
    getAbilitiesUser(state) {
        return state.userAuth.abilities;
    },
    getConfigurationName(state) {
        return state.userAuth.name
    },
    getConfigurationEmail(state) {
        return state.userAuth.email
    },
    getStringRolesUser(state) {
        return _.toString(_.map(state.userAuth.roles, role => role.title));
    },
    getWarehousesfromuser(state){
        return state.userAuth.whs;
    }

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}