let prefix = 'api/sap/';

// trae todos los almacenes
function getItems(dbname) {
    return window.axios.get(prefix+'articles', {params:{dbname: dbname}});
}

function getOneArticle(dbname,code) {
    return window.axios.get(prefix+'article/', {params:{dbname: dbname,code:code}});
}


export default {
    getItems,
    getOneArticle
}