const namespaced = true

import api from '../../../api/Catalogue/ticketState.js';
import _ from 'lodash';

const state = {
    ticketState: [],
    catStatusBefore: '',
    catStatusAfter: ''
}
const mutations = {
    MUTATION_SET_TICKET_STATE(state, value) {
        state.ticketState = value;
    },
    MUTATION_SET_TICKET_STATE_AFTER(state, id) {
        state.catStatusAfter = _.find(state.ticketState, { id });
    },
    MUTATION_SET_TICKET_STATE_BEFORE(state, id) {
        state.catStatusBefore = _.find(state.ticketState, { id });
    },
}
const actions = {
    getTicketState(context) {
        return new Promise((resolve, reject) => {
            api.get().then(res => {
                context.commit('MUTATION_SET_TICKET_STATE', res.data.data);
                resolve(res.data);
            }).catch(function (error) {
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            })
        })
    },
    getAfterStatus(context, id) {
        context.commit('MUTATION_SET_TICKET_STATE_AFTER', id);
    },
    getBeforeStatus(context, id) {
        context.commit('MUTATION_SET_TICKET_STATE_BEFORE', id);
    }
}
const getters = {

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
